import './App.scss';
import React from 'react';
import Poster from './assets/images/poster.png';
import { ReactComponent as Icon3d } from './assets/images/ui_opensea_3D.svg';
import { ReactComponent as IconPfp } from './assets/images/ui_opensea_PFP.svg';
import Logo from './assets/images/VOLTZ_logo_2x.png';
//import { ReactComponent as Logo } from './assets/images/VOLTZ_logo.svg';
//import IconPOD from './assets/images/ui_opensea_POD.svg';
import "@google/model-viewer";

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPanel: 'threed',
            tokenId: null,
            tokenImageUrl: null,
            tokenModelUrl: null,
            backgroundColor: null
        }

        this.pfpLoad = this.pfpLoad.bind(this);
    }

    componentWillMount() {
        let [ slash, context, tokenId ] = window.location.pathname.split('/');
        tokenId = parseInt(tokenId);
        if (tokenId >= 1 && tokenId <= 9999) {
            fetch("https://assets.voltz.me/avatar/" + tokenId)
                .then(response => response.json())
                .then(json => {
                    this.setState({
                        tokenId: tokenId,
                        tokenImageUrl: json.image,
                        tokenModelUrl: json.model,
                    })
                });
        }
    }

    pfpLoad(e) {
        const { width, height } = e.target;
        e.target.crossOrigin = "Anonymous";
        const canvas = document.createElement("canvas");
        canvas.height = height;
        canvas.width = width;
        const context = canvas.getContext?.("2d");
        if (context === null) {
          return;
        }
        context.drawImage(e.target, 0, 0);
        const p = context.getImageData((width * 2 / 3), 1, 1, 1).data;
        const backgroundColor = ((p[0] << 16) | (p[1] << 8) | p[2]).toString(16);
        this.setState({
            backgroundColor: '#' + backgroundColor
        });
    }

    render() {
        if (this.state.tokenId) {
            return (
                <div className="app">
                    <div className="logo">
                        <img src={ Logo } />
                    </div>
                    <div className="controls">
                        <div className={ 'control' + (this.state.selectedPanel === 'pfp' ? '' : ' inactive') } onClick={ () => { this.setState({ selectedPanel: 'pfp' }); document.body.style.backgroundColor = this.state.backgroundColor }}>
                            <IconPfp />
                        </div>
                        <div className={ 'control' + (this.state.selectedPanel === 'threed' ? '' : ' inactive') } onClick={ () => { this.setState({ selectedPanel: 'threed' }); document.body.style.backgroundColor = 'unset' } }>
                            <Icon3d />
                        </div>
                    </div>
                    <div className="content">
                        <div className={ 'pfp' + (this.state.selectedPanel === 'pfp' ? '' : ' hidden') }>
                            <img src={ this.state.tokenImageUrl } onLoad={ this.pfpLoad } alt="" />
                        </div>
                        <div className={ 'threed' + (this.state.selectedPanel === 'threed' ? '' : ' hidden') }>
                            <model-viewer ar camera-controls auto-rotate
                                poster={ Poster }
                                src={ this.state.tokenModelUrl }
                                ar-modes="webxr scene-viewer quick-look"
                                shadow-intensity="1"
                                touch-action="pan-y"
                                exposure="0.9" />
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="app"></div>
            );
        }
    }
}
